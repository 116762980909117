





















































import {
  Component,
  Vue
} from 'vue-property-decorator';
 

import {
  Auth
} from '@/store/auth'
import {
  Core
} from '@/store/core'
import {
  User
} from '@/store/user'
import _ from "lodash";
import moment from 'moment'
@Component({
  components: {
     
  },
})
export default class Home extends Vue {

  private user:any = {}
  private years:any = []
  private response:boolean = false
  public async created(){ 
    let year =  Number(moment().format('YYYY'))+543
 
    this.user = await User.getUser();
    this.years = await Core.getHttp(`/api/ita/v1/year/`)
    this.years = _.filter(this.years,{year:String(year)}) 
    for (let i =0 ;i < this.years.length;i++){
      let res = await Core.getHttp(`/api/ita/v1/rateresult/?rate__year=${this.years[i].id}&agency=${this.$route.query.id}`)
      let rate = await Core.getHttp(`/api/ita/v2/rate/${this.years[i].id}/`)
      this.years[i].result =  await this.getOITResult(res)
      this.years[i].rate = rate.length
    }
    this.response = true
    console.log(this.years)
  }


  private async getOITResult(raw:any){
    let result = await _(raw)
        .groupBy('rate')
        .map(function(items, data) {
          return {
            data: data,
          };
        }).value();
    return result.length;

  }

}
